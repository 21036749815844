.title {
  position: fixed;
  z-index: 100000;
  left: 2rem;
  border-bottom: 5px solid white;
}

.mainSlider {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}